export default {
  root: {
    display: 'flex',
    width: '100%',
    justifyContent: 'space-evenly',
    alignItems: 'center'
  },
  datacard: {
    display: 'flex',
    alignItems: 'center',
    padding: '10px 60px 10px 20px',
    '& div': {
      marginRight: '20px',
      fontWeight: 500,
      color: '#646C9A'
    }
  },
  noDatacardCheckboxContainer: { width: '200px', height: '58px' },
  withoutMsisdn: {
    justifyContent: 'start'
  }
};
