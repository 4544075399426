import React, { cloneElement, useEffect, useMemo, useState } from 'react';
import {
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  withStyles
} from '@material-ui/core';
import flowRight from 'lodash.flowright';
import { connect } from 'react-redux';
import Select from 'react-select';

import CustomDialogTitle from '../../../partials/customDialogTitle/CustomDialogTitle';
import DraggableDialog from '../../../partials/DraggableDialog';
import styles, { customSubSelectStyles } from '../styles/changeSubscription';
import { translate } from '../../../i18n/I18nProvider';
import Optional from '../../../utils/optional';
import DataRow from '../../../partials/Datarow/DataRow';
import PrimaryButton from '../../../partials/customButtons/PrimaryButton';
import SecondaryButton from '../../../partials/customButtons/SecondaryButton';
import { actions as reduxActions } from '../reducers';
import getProducts from '../actions/getProducts';
import {
  doesArrayHasLength,
  isArray,
  isArrayEmpty,
  isDefined,
  isUndefined
} from '../../../utils/isDefined';
import { reactSelectDefaultStyles } from '../../../consts/reactSelectDefaultStyles';
import moment from 'moment';
import Summary from './Summary';
import changeSubscription from '../actions/changeSubscription';

const ChangeSubscription = ({
  children,
  classes,
  subscription,
  clientId,
  lang,
  isMultiedit = false,
  selectedRows = [],
  openSummary,
  selectedAccount,
  closeMenu = () => {}
}) => {
  const [open, setOpen] = useState(false);
  const [products, setProducts] = useState([]);
  const [product, setProduct] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [changeDate, setChangeDate] = useState('');

  useEffect(() => {
    closeMenu();
    const today = moment()
      .add(1, 'M')
      .format('x');
    setChangeDate(Number(today));
    if (
      open &&
      ((isMultiedit && isDefined(selectedAccount)) ||
        (!isMultiedit && isDefined(subscription?.accountReference)))
    ) {
      (async function() {
        await getProducts({
          setProducts,
          setIsLoading,
          accountReference: isMultiedit
            ? selectedAccount?.externalReference
            : subscription?.accountReference,
          clientId
        });
      })();
    }

    // eslint-disable-next-line
  }, [selectedAccount, clientId, open, subscription, isMultiedit]);

  const onOpenDialog = () => {
    setOpen(true);
    setProducts([]);
    setIsLoading(false);
  };

  const onCloseDialog = () => {
    setOpen(false);
  };

  const customClasses = {
    root: classes.datarowRoot,
    label: classes.dataRowLabel,
    value: classes.datarowValue
  };

  const onChangeClick = async () => {
    const { requestCompleted, summaryInfo } = await changeSubscription({
      clientId,
      subscription,
      isMultiedit,
      selectedRows,
      setIsLoading,
      newProduct: product,
      changeDate
    });

    if (requestCompleted) {
      const subs = Object.keys(summaryInfo);

      const errors = subs.filter(sub => {
        const statuses = summaryInfo[sub].status;

        if (!isArray(statuses)) {
          return false;
        }

        const filteredStatuses = statuses.find(el => el.isError === true);

        return isDefined(filteredStatuses);
      });

      openSummary({
        summaryInfo,
        fromChange: true,
        isSummaryError: doesArrayHasLength(errors)
      });

      if (isArrayEmpty(errors)) {
        onCloseDialog();
      }
    }
  };

  const handleChange = selected => {
    setProduct(selected);
  };

  const productsOptions = useMemo(() => {
    return Optional(
      isMultiedit
        ? products[selectedAccount?.externalReference]
        : products[subscription?.accountReference]
    ).or([]);
  }, [selectedAccount, products, subscription, isMultiedit]);

  return (
    <>
      {cloneElement(children, { onClick: onOpenDialog })}
      <Dialog
        maxWidth="lg"
        classes={{
          paper: classes.root
        }}
        PaperComponent={DraggableDialog}
        open={open}
        onClose={(event, reason) => {
          if (reason !== 'backdropClick') {
            onCloseDialog();
          }
        }}
      >
        <CustomDialogTitle onCloseClick={onCloseDialog}>
          {translate(
            isMultiedit
              ? 'BUTTON.MULTIEDIT'
              : 'SUBSCRIPTIONS.CHANGE_SUBSCRIPTION'
          )}
        </CustomDialogTitle>
        {isLoading && (
          <div className={classes.loaderOverlay}>
            <CircularProgress />
          </div>
        )}
        <DialogContent className={classes.dialogContent}>
          {isLoading && (
            <div className={classes.loaderOverlay}>
              <CircularProgress />
            </div>
          )}
          <div className={classes.editContainer}>
            {!isMultiedit ? (
              <>
                <DataRow
                  label={translate('PRODUCT')}
                  value={Optional(subscription?.skuTranslations?.[lang]).or(
                    subscription?.skuTranslations?.default
                  )}
                  customClasses={customClasses}
                />
                <DataRow
                  label={translate('NAME')}
                  value={Optional(subscription?.name).or('')}
                  customClasses={customClasses}
                />
              </>
            ) : (
              <DataRow
                label={translate('MENU.SUBSCRIPTIONS')}
                value={selectedRows.map(el => el.name).join(', ')}
                customClasses={customClasses}
              />
            )}
            <div className={classes.selectContainer}>
              <div className={classes.label}>
                {translate('REQUEST_NEW_USER.SUBSCRIPTION_STEP')}
              </div>
              <Select
                options={productsOptions}
                styles={reactSelectDefaultStyles}
                customStyles={customSubSelectStyles}
                value={product}
                onChange={handleChange}
                isDisabled={
                  isUndefined(selectedAccount) || isArrayEmpty(productsOptions)
                }
                maxMenuHeight={120}
              />
            </div>
            <div className={classes.info}>
              {translate('SUBSCRIPTIONS.SUMMARY_TEXT_2')}
            </div>
          </div>
        </DialogContent>
        <DialogActions className={classes.footer}>
          <SecondaryButton onClick={onCloseDialog}>
            {translate('BUTTON.CANCEL')}
          </SecondaryButton>
          <PrimaryButton
            onClick={onChangeClick}
            disabled={
              productsOptions.length < 3 ||
              (!isMultiedit && subscription?.sku === product?.value) ||
              isUndefined(product)
            }
          >
            {translate('SUBSCRIPTIONS.CHANGE_SUBSCRIPTION')}
          </PrimaryButton>
        </DialogActions>
      </Dialog>
      <Summary />
    </>
  );
};

const mapStatesToProps = ({ selectClient, auth, iotProducts }) => {
  return {
    clientId: Optional(selectClient.selectedClient?.id).or(void 0),
    lang: auth.lang,
    selectedAccount: iotProducts.selectedAccount
  };
};

const mapDispatchToProps = {
  openSummary: reduxActions.openSummary
};

export default flowRight(
  connect(mapStatesToProps, mapDispatchToProps),
  withStyles(styles)
)(ChangeSubscription);
