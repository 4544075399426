import { getData } from '../../../utils/http';
import { JSONAPI_URLS, URLS } from '../../../consts/endpoints';
import { isDefined } from '../../../utils/isDefined';
import Optional from '../../../utils/optional';
import urlCreator from '../../../utils/urlCreator';

const QUERY_PARAMS = {
  include: 'primary_card'
};

const getSimcard = async ({ id, number }) => {
  let simcard = {};
  let activationCode = void 0;
  let isESimCard = false;

  try {
    if (isDefined(number)) {
      const { data } = await getData(
        urlCreator(JSONAPI_URLS.SIM_CARDS, {
          ...QUERY_PARAMS,
          filter: { msisdn: number }
        })
      );
      if (isDefined(data[0])) {
        simcard = data[0];
      }
    } else {
      const { data } = await getData(
        urlCreator(`${URLS.SIM_CARDS}/${id}.json`, QUERY_PARAMS)
      );

      simcard = data;
    }
    if (isDefined(simcard?.icc) && isDefined(simcard?.client_id)) {
      try {
        const response = await getData(
          `${URLS.QUOTE_CLIENTS}/${simcard.client_id}/resources/search?icc=${[
            simcard.icc
          ]}`
        );
        activationCode = Optional(response?.activationCode).or(void 0);
        isESimCard = response?.simCardType === 'ESIM';

      } catch {
        return void 0;
      }
    }
  } finally {
    return { simcard, activationCode, isESimCard };
  }
};

export default getSimcard;
