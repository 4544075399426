import {
  Checkbox,
  createTheme,
  ThemeProvider,
  Tooltip,
  withStyles
} from '@material-ui/core';
import React from 'react';

import styles from './styles';
import Optional from '../../utils/optional';
import flowRight from 'lodash.flowright';
import withSettings from '../../utils/withSettings';
import clsx from 'clsx';

const DataCheckboxRow = ({
  label,
  name,
  classes,
  width,
  values,
  handleBlur,
  handleChange,
  disabled,
  settings,
  infoIcon = false,
  infoIconTooltipComponent = void 0,
  infoIconText = ''
}) => {
  const theme = createTheme({
    overrides: {
      MuiSvgIcon: { root: { fontSize: '25px' } },
      MuiCheckbox: {
        root: { fontSize: '20px' },
        colorSecondary: {
          color: settings.colors.modal_header,
          '&$checked': { color: settings.colors.modal_header }
        }
      }
    }
  });
  return (
    <div
      className={classes.checkboxRoot}
      style={{ minWidth: width ? `${width}px` : '260px' }}
    >
      <ThemeProvider theme={theme}>
        <Checkbox
          name={name}
          checked={Optional(values[name]).or(false)}
          onBlur={handleBlur}
          onChange={handleChange}
          disabled={disabled}
        />
      </ThemeProvider>
      <div className={classes.label}>
        {label}
        {infoIcon && (
          <Tooltip title={Optional(infoIconTooltipComponent).or(infoIconText)}>
            <i className={clsx('fas fa-info-circle', classes.infoIcon)}></i>
          </Tooltip>
        )}
      </div>
    </div>
  );
};

export default flowRight(withSettings, withStyles(styles))(DataCheckboxRow);
