import {
  createTheme,
  TextField,
  ThemeProvider,
  Tooltip,
  withStyles
} from '@material-ui/core';
import React from 'react';

import styles from './styles';
import clsx from 'clsx';

const DataInputBox = ({
  name,
  label,
  handleBlur,
  handleChange,
  values = {},
  errors = {},
  classes,
  width = 200,
  disabled = false,
  infoIcon = false,
  infoIconText
}) => {
  const theme = createTheme({
    overrides: {
      MuiOutlinedInput: {
        input: {
          width,
          padding: '12px',
          height: '23px',
          backgroundColor: '#FFF'
        }
      }
    }
  });

  return (
    <div
      className={classes.root}
      style={{ minWidth: width ? `${width}px` : '260px' }}
    >
      <div className={classes.label}>
        {label}
        {infoIcon && (
          <Tooltip title={infoIconText}>
            <i className={clsx('fas fa-info-circle', classes.infoIcon)}></i>
          </Tooltip>
        )}
      </div>
      <ThemeProvider theme={theme}>
        <TextField
          variant="outlined"
          margin="dense"
          name={name}
          value={values[name]}
          onBlur={handleBlur}
          onChange={handleChange}
          error={Boolean(errors[name])}
          disabled={disabled}
          inputProps={{
            autoComplete: 'off',
            'data-lpignore': true
          }}
        ></TextField>
      </ThemeProvider>
    </div>
  );
};

export default withStyles(styles)(DataInputBox);
