import { URLS } from '../../../consts/endpoints';
import { translate } from '../../../i18n/I18nProvider';
import store from '../../../store/store';
import { getData, postData } from '../../../utils/http';
import { isDefined, isString } from '../../../utils/isDefined';
import { actions as errorReduxActions } from '../../../partials/errorDialog/reducers';
import RESPONSE_CODES from '../../../consts/responseCodes';
import Optional from '../../../utils/optional';

const eSimCodeRequest = async ({ number, clientId }) => {
  try {
    const subscriptions = await getData(
      `${URLS.QUOTE_CLIENTS}/${clientId}/subscriptions/search?phoneNumber[]=${number}`
    );

    if (Object.keys(subscriptions).indexOf('error') === -1) {
      const subscriptionId = subscriptions[number][0].id;

      if (isDefined(subscriptionId)) {
        const { data } = await postData({
          url: `${URLS.QUOTE_CLIENTS}/${clientId}/subscriptions/${subscriptionId}/activate_esim`,
          errorsToPass: [RESPONSE_CODES.INTERNAL_ERROR]
        });

        return data;
      }
      return void 0;
    } else {
      if (
        isString(subscriptions.error.status) &&
        subscriptions.error.status.indexOf('404') !== -1
      ) {
        store.dispatch(
          errorReduxActions.openDialog({
            directMessage: `${translate(
              'ORDER_ESIM_ERROR_1'
            )} ${number}. ${translate('CONTACT_SUPPORT')}`
          })
        );
      }
      return void 0;
    }
  } catch (e) {
    store.dispatch(
      errorReduxActions.openDialog({
        directMessage: `${translate(
          'ORDER_ESIM_ERROR_1'
        )} ${number}. ${translate('CONTACT_SUPPORT')} \n ${Optional(
          e?.response?.data?.[0]?.message
        )
          .map(message => `Error message : ${message}`)
          .or('')}`
      })
    );
    return void 0;
  }
};

export default eSimCodeRequest;
