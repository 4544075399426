export default {
    root: {
      display: 'flex',
      flexDirection: 'column',
      width:'550px',
      padding: '10px 0 0 30px'
    },
    label: {
      color: '#646C9A',
      fontWeight: 500,
      marginBottom: '20px'
    }
  };