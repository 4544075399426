import orderBy from 'lodash.orderby';
import {
  isArrayEmpty,
  isDefined,
  isStringDefined,
  isUndefined
} from '../../../utils/isDefined';
import Optional from '../../../utils/optional';

export default function mapMitelData({ response, externalNo, simCards }) {
  const mappedMitelData = response.map(el => {
    const {
      range_from,
      range_up_to_incl,
      assigned_numbers = [],
      unassigned_numbers = []
    } = el;
    const rangeFrom = range_from.replace('+', '').trim();
    const rangeTo = range_up_to_incl.replace('+', '').trim();

    const unassigned = unassigned_numbers.map(number => {
      return { number };
    });

    const all = [...assigned_numbers, ...unassigned];

    return {
      rangeTo,
      rangeFrom,
      assigned: assigned_numbers,
      unassigned,
      all: [...orderBy(all, 'number')]
    };
  });

  const mappedNumberRanges = mapNumberRanges(externalNo, mappedMitelData);

  const numberRangesWithSimcardData = mapRanges({
    mappedNumberRanges,
    simCards
  });

  const mappedRangesWithPlatform = mapPlatform(numberRangesWithSimcardData);

  return mappedRangesWithPlatform;
}

function mapNumberRanges(externalNo, mappedMitelData) {
  return externalNo.map(number => {
    let foundedData = null;

    if (
      number.number_type === 'mobile' &&
      number.range_from === number.range_up_to_incl
    ) {
      foundedData = findMitelRange(mappedMitelData, number);
    } else {
      foundedData = findConnectNumberRange(
        mappedMitelData,
        Number(number.range_from)
      );
    }

    if (isUndefined(foundedData)) {
      return { ...number, mitelData: mapNumbersWithoutMitelData(number) };
    }

    if (Number(foundedData.rangeTo) === Number(number.range_up_to_incl)) {
      return { ...number, mitelData: foundedData };
    }

    const lastNumber = Number(foundedData.rangeTo);

    const numbersToCheck = createNumbersArray(
      lastNumber,
      Number(number.range_up_to_incl)
    );

    const mappedRanges = numbersToCheck.reduce(
      (obj, number) => {
        const found = findConnectNumberRange(mappedMitelData, number);

        if (isUndefined(found)) {
          return obj;
        }

        return {
          ...obj,
          assigned: [
            ...orderBy([...obj.assigned, ...found.assigned], 'number')
          ],
          unassigned: [
            ...orderBy([...obj.unassigned, ...found.unassigned], 'number')
          ],
          all: [...orderBy([...obj.all, ...found.all], 'number')]
        };
      },
      { ...foundedData }
    );

    return { ...number, mitelData: mappedRanges };
  });
}

function findConnectNumberRange(mappedMitelData, number) {
  return mappedMitelData.find(data => Number(data.rangeFrom) === number);
}

function createNumbersArray(lastNumber, numberTo) {
  let numbersToCheck = [];

  for (let i = lastNumber + 1; i <= numberTo; i++) {
    numbersToCheck = [...numbersToCheck, i];
  }

  return numbersToCheck;
}

function mapRanges({ mappedNumberRanges, simCards }) {
  return mappedNumberRanges.map(range => {
    if (range.number_type !== 'mobile' || isArrayEmpty(simCards)) {
      return range;
    }

    if (isUndefined(range.mitelData)) {
      const simcardData = simCards.find(
        card => Number(card.msisdn) === range.range_from
      );

      if (isUndefined(simcardData)) {
        return range;
      }

      let assigned = void 0;
      let unassigned = void 0;

      if (simcardData?.active) {
        assigned = {
          number: `+${simcardData.msisdn}`,
          function: 'SIMCARD',
          name: Optional(simcardData.owner?.name).or(''),
          description: Optional(simcardData.description).or('')
        };
      } else {
        unassigned = {
          number: `+${simcardData.msisdn}`
        };
      }

      return {
        ...range,
        mitelData: {
          all: isDefined(assigned) ? [assigned] : [unassigned],
          assigned: isDefined(assigned) ? [assigned] : [],
          unassigned: isDefined(unassigned) ? [unassigned] : []
        }
      };
    }
    const { mitelData } = range;

    const { unassigned, assigned } = mitelData;

    let mappedAssigned = assigned.map(el => {
      const foundedSimcard = simCards.find(
        simcard => simcard.msisdn === el.number.replace('+', '')
      );

      if (isUndefined(foundedSimcard)) {
        return el;
      }

      return {
        ...el,
        description: Optional(foundedSimcard.description).or('')
      };
    });

    const { mappedUnassigned, updatedAssigned } = mapUnassigned(
      unassigned,
      simCards,
      mappedAssigned
    );

    mappedAssigned = updatedAssigned;

    return {
      ...range,
      mitelData: {
        ...mitelData,
        all: [...orderBy([...mappedAssigned, ...mappedUnassigned], 'number')],
        assigned: [...orderBy(mappedAssigned, 'number')],
        unassigned: mappedUnassigned
      }
    };
  });
}

function mapUnassigned(unassigned, simCards, mappedAssigned) {
  let assigned = [...mappedAssigned];
  const mappedUnassigned = unassigned
    .map(el => {
      const foundedSimcard = simCards.find(
        simcard => simcard.msisdn === el.number.replace('+', '')
      );

      if (isUndefined(foundedSimcard) || !foundedSimcard?.active) {
        return el;
      }

      assigned = [
        ...assigned,
        {
          number: el.number,
          function:
            foundedSimcard.card_type !== 'standard'
              ? 'SIMCARD_DATA'
              : 'SIMCARD',
          name: isDefined(foundedSimcard.owner)
            ? Optional(foundedSimcard.owner.name).or('')
            : Optional(foundedSimcard.primary_card?.owner?.name).or(''),
          description: Optional(foundedSimcard.description).or('')
        }
      ];
      return null;
    })
    .filter(el => isDefined(el));

  return { mappedUnassigned, updatedAssigned: assigned };
}

function findMitelRange(mappedMitelData, number) {
  const foundedRange = mappedMitelData.find(
    range =>
      Number(number.range_from) >= range.rangeFrom &&
      Number(number.range_from) <= range.rangeTo
  );

  if (isUndefined(foundedRange)) {
    return null;
  }

  if (foundedRange.rangeFrom === foundedRange.rangeTo) {
    return foundedRange;
  }

  return {
    ...foundedRange,
    all: mapFoundedRangeNumbers(foundedRange.all, number),
    assigned: mapFoundedRangeNumbers(foundedRange.assigned, number),
    unassigned: mapFoundedRangeNumbers(foundedRange.unassigned, number),
    filteredNumber: true
  };
}

function mapFoundedRangeNumbers(rangeNumbers, number = {}) {
  if (isUndefined(rangeNumbers) || isUndefined(number)) {
    return [];
  }

  return rangeNumbers.map(rangeNumber => {
    if (
      Number(rangeNumber.number.replace('+', '').trim()) === number.range_from
    ) {
      return { ...rangeNumber, founded: true };
    }

    return { ...rangeNumber };
  });
}

function mapNumbersWithoutMitelData(number) {
  if (number.status !== 'inactive' && number.status !== 'outported') {
    return null;
  }

  const numbersArray = createNumbersArray(
    number.range_from - 1,
    number.range_up_to_incl
  ).map(number => {
    return { number: `+${number}` };
  });

  return { all: numbersArray, assigned: [], unassigned: numbersArray };
}

const mapPlatform = (data = []) => {
  return data.map(el => {
    const platform = isStringDefined(el.csbc_routing)
      ? el.csbc_routing
      : isStringDefined(el.client?.csbc_routing)
      ? el.client?.csbc_routing
      : '';

    const isPlatfromFormRange = isStringDefined(el.csbc_routing);

    return { ...el, platform, isPlatfromFormRange };
  });
};
