import { postData } from '../../../utils/http';
import { URLS } from '../../../consts/endpoints';
import { actions as reduxActions } from '../reducers';
import RESPONSE_CODES from '../../../consts/responseCodes';
import showSnackbar, { showErrorSnackbar } from '../../../utils/showSnackbar';
import { mapErrorMessage } from '../../../utils/mapErrorMessage';
import { translate } from '../../../i18n/I18nProvider';

export default function swapCard({ id, prefix, values }) {
  const fullIcc = `${prefix}${values.new_sim_card_icc}`;

  return async dispatch => {
    try {
      const response = await postData({
        url: `${URLS.SIM_CARDS}/${id}/swap`,
        data: {
          new_sim_card_icc: fullIcc
        },
        errorsToPass: [RESPONSE_CODES.INTERNAL_ERROR]
      });
      if (response?.status === RESPONSE_CODES.SUCCESS) {
        showSnackbar({
          intlTextKey: 'MESSAGE.SWAP_CARD_SEND',
          messageType: 'success'
        });
        dispatch(reduxActions.setCompleted(fullIcc));
      }
    } catch (e) {
      const errorMessage = mapErrorMessage(
        e.response,
        translate('MESSAGE.CONTACT_SUPPORT')
      );

      showErrorSnackbar({
        errorMessage,
        messageType: 'error',
        time: 8000,
        showEndpoint: true
      });
    }
  };
}
