import React from 'react';
import { withStyles } from '@material-ui/styles';
import clsx from 'clsx';
import flowRight from 'lodash.flowright';
import { injectIntl } from 'react-intl';

import styles from '../styles/generalSection';
import AttributeRow from './AttributeRow';
import Optional from '../../../utils/optional';

const VALUE_FIELD_WIDTH = {
  wide: '230px',
  narrow: '160px'
};

export function GeneralSection({ classes, field, intl }) {
  const { value } = field;

  const showPIN = Optional(value?.mobile_network?.brand)
    .map(brand => brand === 'TDC' || brand === 'Telenor')
    .or(false);

  return (
    <div className={classes.root}>
      <div className={classes.column}>
        <AttributeRow
          data={value.icc}
          label="ICC :"
          width={VALUE_FIELD_WIDTH.wide}
        />
        <AttributeRow
          data={value.msisdn}
          label="MSISDN :"
          width={VALUE_FIELD_WIDTH.wide}
          shouldDataBeHashed={false}
        />
      </div>
      <div className={classes.column}>
        <AttributeRow
          data={showPIN ? value.pin1 : ''}
          label="PIN 1 :"
          width={VALUE_FIELD_WIDTH.narrow}
          shouldDataBeHashed={showPIN}
          altTooltip={intl.formatMessage({
            id: 'TOOLTIP.SIMCARD_PIN_INFO'
          })}
        />
      </div>
      <div className={clsx(classes.column, classes.pukColumn)}>
        <AttributeRow
          data={showPIN ? value.puk1 : ''}
          label="PUK 1 :"
          width={VALUE_FIELD_WIDTH.narrow}
          shouldDataBeHashed={showPIN}
          altTooltip={intl.formatMessage({
            id: 'TOOLTIP.SIMCARD_PIN_INFO'
          })}
        />
      </div>
    </div>
  );
}

export default flowRight(injectIntl, withStyles(styles))(GeneralSection);
