const styles = theme => {
  return {
    content: { display: 'flex', flexDirection: 'column', alignItems: 'center' },
    label: {
      marginBottom: '20px'
    },
    messageContainer: {
      fontSize: '12px',
      visibility: 'hidden',
      margin: '10px 0',
      textAlign: 'center',
      color: '#F00'
    },
    messageVisible: {
      visibility: 'visible'
    },
    iccContainer: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      marginTop: '10px'
    },
    infoText: {
      padding: '10px 10px 0',
      fontSize: '13px',
      textAlign: 'center'
    },
    link: {
      cursor: 'pointer',
      '&:hover': { filter: 'brightness(1.5)' },
      marginLeft: '4px'
    },
    hiddenBox: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center'
    },
    infoTextHidden: {
      padding: '10px 20px 10px',
      fontSize: '13px',
      textAlign: 'center'
    },
    footer: {
      display: 'flex',
      justifyContent: 'center',
      padding: '10px 0',
      '& button': {
        marginRight: '10px',
        [theme.breakpoints.down('xs')]: {
          marginTop: '10px'
        }
      },
      [theme.breakpoints.down('xs')]: {
        flexDirection: 'column',
        alignItems: 'center'
      }
    }
  };
};

export default styles;

export const customStyles = {
  textfieldRoot: { width: '280px' },
  textfieldRootEuicc: { width: '390px' }
};
