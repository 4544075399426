import { withStyles } from '@material-ui/core';
import React from 'react';

import styles from './styles';

const DataBox = ({ label, value, classes, width }) => {
  return (
    <div
      className={classes.root}
      style={{ minWidth: width ? `${width}px` : '260px' }}
    >
      <div className={classes.label}>{label}</div>
      <div className={classes.value}>{value}</div>
    </div>
  );
};

export default withStyles(styles)(DataBox);
