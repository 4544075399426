import React, { useCallback } from 'react';
import { withStyles } from '@material-ui/styles';
import Select from 'react-select';
import { connect } from 'react-redux';
import flowRight from 'lodash.flowright';
import { injectIntl } from 'react-intl';

import styles, { customSelectStyles } from '../styles/selectsSection';
import { reactSelectDefaultStyles } from '../../../consts/reactSelectDefaultStyles';
import { TextField } from '@material-ui/core';
import Optional from '../../../utils/optional';

export function SelectsSection({
  classes,
  form,
  intl,
  selectOptions = {},
  isFieldDisabled = true,
  customer,
  defaultClientName,
  isIoTCard
}) {
  const { setFieldValue, values, handelBlur } = form;
  const { users = [] } = selectOptions;
  const clientValue = Optional(customer).or(defaultClientName);
  const userValue = users.find(option => option.value === values.user_id);

  const handleUserChange = useCallback(
    (selected, name) => {
      setFieldValue(name, selected.value);
    },
    // eslint-disable-next-line
    []
  );

  return (
    <div className={classes.root}>
      <div className={classes.container}>
        <div className={classes.label}>{`${intl.formatMessage({
          id: 'TABLE.NETWORK'
        })} :`}</div>
        <TextField
          InputProps={{
            classes: {
              disabled: classes.textFieldDisabled
            }
          }}
          margin="dense"
          variant="outlined"
          className={classes.clientField}
          value={Optional(values.mobile_network?.brand).or('')}
          disabled
          inputProps={{ autoComplete: 'off', 'data-lpignore': true }}
        />
      </div>
      <div className={classes.container}>
        <div className={classes.label}>{`${intl.formatMessage({
          id: 'CUSTOMER'
        })} :`}</div>
        {isFieldDisabled ? (
          <TextField
            InputProps={{
              classes: {
                disabled: classes.textFieldDisabled
              }
            }}
            margin="dense"
            variant="outlined"
            className={classes.clientField}
            value={clientValue}
            disabled
            inputProps={{ autoComplete: 'off', 'data-lpignore': true }}
          />
        ) : (
          <Select
            customStyles={customSelectStyles}
            options={[]}
            styles={reactSelectDefaultStyles}
            value={clientValue}
            isSearchable
            isDisabled={isFieldDisabled}
            onBlur={handelBlur}
          />
        )}
      </div>
      {!isIoTCard && (
        <div className={classes.container}>
          <div className={classes.label}>{`${intl.formatMessage({
            id: 'OWNER'
          })} :`}</div>
          <Select
            customStyles={customSelectStyles}
            options={users}
            styles={reactSelectDefaultStyles}
            value={userValue}
            isSearchable
            onBlur={handelBlur}
            onChange={selected => handleUserChange(selected, 'user_id')}
          />
        </div>
      )}
    </div>
  );
}

const mapStatesToProps = ({ editSimcard, selectClient }) => {
  return {
    isIoTCard: editSimcard.isIoTCard,
    selectOptions: editSimcard.selectOptions,
    customer: editSimcard.simcard?.client?.name,
    defaultClientName: selectClient.selectedClient.name
  };
};

export default flowRight(
  injectIntl,
  connect(mapStatesToProps),
  withStyles(styles)
)(SelectsSection);
