export default {
  root: {
    display: 'flex',
    fontSize: '12px',
    fontStyle: 'normal',
    fontWeight: 'normal',
    padding: '20px 60px 20px 20px',
    justifyContent: 'space-between'
  },
  column: {
    display: 'inherit',
    flexDirection: 'column',marginRight:'10px'
  },
  label: {
    fontSize: '14px',
    fontWeight: 500,
    marginBottom: '20px'
  }
};
