export default {
  dialogContent: {
    paddingBottom: '10px',
    overflow: 'visible'
  },
  title: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%'
  },
  scrollPaper: {
    alignItems: 'start'
  },
  lastRowContainer: {
    display: 'flex',
    fontSize: '12px',
    fontStyle: 'normal',
    fontWeight: 'normal',
    padding: '20px 60px 20px 20px',
    justifyContent: 'space-between'
  },
  footer: {
    display: 'flex',
    justifyContent: 'center',
    '& button': {
      marginRight: '10px'
    }
  }
};
