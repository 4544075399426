import React from 'react';
import { withStyles } from '@material-ui/styles';
import HashedData from '../../../partials/HashedData';

import styles from '../styles/attributeRow';
import Optional from '../../../utils/optional';

export function AttributeRow({
  classes,
  data,
  label,
  width,
  shouldDataBeHashed,
  altTooltip
}) {
  return (
    <div className={classes.root}>
      <div className={classes.label}>{label}</div>
      <HashedData
        data={Optional(data).or('')}
        style={{ ...styles.hashedField, width }}
        shouldDataBeHashed={shouldDataBeHashed}
        altTooltip={altTooltip}
      />
      <div></div>
    </div>
  );
}

export default withStyles(styles)(AttributeRow);
