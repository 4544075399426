import flowRight from 'lodash.flowright';
import React from 'react';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/styles';

import styles from '../styles/subscriptionsData';
import { isArray } from '../../../utils/isDefined';
import Problems from '../../../pages/user/components/Problems';

const SubscriptionData = ({ classes, subscription }) => {
  return (
    <div className={classes.root}>
      <div className={classes.nameContainer}>
        <div className={classes.label}>Product :</div>
        <div>{subscription?.skuTranslations?.default}</div>
      </div>
      {isArray(subscription?.problems) && (
        <Problems problems={subscription?.problems} />
      )}
    </div>
  );
};

const mapStatesToProps = ({ editSimcard }) => {
  return {
    subscription: editSimcard.subscription
  };
};

export default flowRight(
  connect(mapStatesToProps),
  withStyles(styles)
)(SubscriptionData);
