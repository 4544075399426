export default {
  root: {
    display: 'flex',
    height: '40px',
    alignItems: 'center',
    margin: '3px 0'
  },
  label: {
    color: '#646C9A',
    width: '55px',
    fontWeight: 500,
    marginRight: '20px'
  },
  hashedField: {
    backgroundColor: '#E4E3E3',
    height: '40px',
    justifyContent: 'flex-start',
    paddingLeft: '5px',
    borderRadius: '5px',
  }
};
