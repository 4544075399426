import React from 'react';
import { withStyles } from '@material-ui/styles';
import { TextField } from '@material-ui/core';
import flowRight from 'lodash.flowright';
import { injectIntl } from 'react-intl';

import styles from '../styles/description';
import Optional from '../../../utils/optional';

export function Description({ classes, field, intl }) {
  const { onBlur, name, value, onChange } = field;
  return (
    <div className={classes.root}>
      <div className={classes.label}>
        {intl.formatMessage({
          id: 'DESCRIPTION'
        })}
      </div>
      <TextField
        fullWidth={true}
        variant="outlined"
        margin="dense"
        onBlur={onBlur}
        onChange={onChange}
        value={Optional(value).or('')}
        name={name}
        inputProps={{ autoComplete: 'off', 'data-lpignore': true }}
      />
    </div>
  );
}

export default flowRight(injectIntl, withStyles(styles))(Description);
