import React, { useMemo } from 'react';
import { connect } from 'react-redux';
import {
  Dialog,
  DialogContent,
  DialogActions,
  Button
} from '@material-ui/core';
import flowRight from 'lodash.flowright';
import { withStyles } from '@material-ui/styles';

import styles from '../styles/summary';
import { actions as reduxActions } from '../reducers';
import { translate } from '../../../i18n/I18nProvider';
import CustomDialogTitle from '../../../partials/customDialogTitle/CustomDialogTitle';
import withSettings from '../../../utils/withSettings';
import Optional from '../../../utils/optional';
import { isArray } from '../../../utils/isDefined';

const Summary = ({
  summaryOpen,
  summaryInfo = {},
  closeDialog,
  classes,
  settings,
  fromChange,
  isSummaryError
}) => {
  const handleClose = () => {
    closeDialog();
  };

  const subsArray = useMemo(() => Object.keys(summaryInfo), [summaryInfo]);

  return (
    <Dialog open={summaryOpen} onClose={handleClose}>
      <CustomDialogTitle onCloseClick={handleClose}>
        {!fromChange
          ? translate('SUBSCRIPTIONS.CANCEL_SUBSCRIPTION')
          : translate('SUBSCRIPTIONS.CHANGE_SUBSCRIPTION')}
      </CustomDialogTitle>
      <DialogContent className={classes.content}>
        <div className={classes.summaryTextContainer}>
          {fromChange ? (
            <>
              <div>{translate('SUBSCRIPTIONS.SUMMARY_TEXT_1')}</div>
              {isArray(subsArray) &&
              subsArray.lenght === 1 &&
              !isSummaryError ? (
                <div>{translate('SUBSCRIPTIONS.SUMMARY_TEXT_2')}</div>
              ) : null}
            </>
          ) : (
            <>
              <div>{translate('SUBSCRIPTIONS.SUMMARY_TEXT_1')}</div>
              <div>{translate('SUBSCRIPTIONS.CANCEL_TEXT_2')}</div>
            </>
          )}
        </div>
        {subsArray.map(name => {
          const { status = [], changeInfo = {} } = summaryInfo[name];

          return (
            <div className={classes.numberContainer} key={name}>
              <div className={classes.row}>
                <div className={classes.label}>{translate('NAME')}</div>
                <div>
                  <div>{name}</div>
                </div>
              </div>
              {fromChange && (
                <div>
                  <div className={classes.row}>
                    <div className={classes.label}>{translate('FROM')}</div>
                    <div>{changeInfo.from}</div>
                  </div>
                  <div className={classes.row}>
                    <div className={classes.label}>{translate('TO')}</div>
                    <div>{changeInfo.to}</div>
                  </div>
                </div>
              )}
              <div className={classes.row}>
                <div className={classes.label}>Status</div>
                {status.map((info, i) => (
                  <div key={i}>
                    <div
                      style={{
                        color: info.isError
                          ? settings.colors.danger
                          : settings.colors.success
                      }}
                    >
                      {info.isError ? (
                        <div>{translate('FAILURE')}</div>
                      ) : (
                        <div>
                          {Optional(info.text).or(translate('SUCCESS'))}
                        </div>
                      )}
                    </div>
                    {info.isError &&
                      info.errors.map((error, i) => (
                        <div
                          className={classes.error}
                          key={i}
                          style={{ color: settings.colors.danger }}
                        >
                          {error}
                        </div>
                      ))}
                  </div>
                ))}
              </div>
            </div>
          );
        })}
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>
          {isSummaryError
            ? translate('BUTTON.BACK')
            : translate('BUTTON.CLOSE')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const mapStatesToProps = ({ iotProducts }) => {
  const { summaryOpen, summaryInfo, fromChange, isSummaryError } = iotProducts;
  return { summaryOpen, summaryInfo, fromChange, isSummaryError };
};

const mapDispatchToProps = {
  closeDialog: reduxActions.closeSummary
};

export default flowRight(
  withSettings,
  connect(mapStatesToProps, mapDispatchToProps),
  withStyles(styles)
)(Summary);
