import React, {
  useState,
  useMemo,
  useEffect,
  useCallback,
  useRef
} from 'react';
import { connect } from 'react-redux';
import MaterialTable, { MTableToolbar } from '@material-table/core';
import flowRight from 'lodash.flowright';
import { injectIntl } from 'react-intl';
import { withRouter } from 'react-router-dom';
import { withStyles } from '@material-ui/styles';

import {
  mapTableOptions,
  setLocalization,
  TABLE_OPTIONS
} from '../../../consts/tableOptions';
import AddButton from '../../../partials/tableCustomButtons/AddButton';
import tableColumns from './tableColumns';
import { isDefined, isUndefined } from '../../../utils/isDefined';
import {
  setTableColumns,
  updateTableColumns
} from '../../../utils/localstorageUtils';
import { actions as reduxActions } from '../reducers';
import customTableIcons from '../../../partials/tableIcons/customTableIcons';
import RangesTableHeader from './RangesTableHeader';
import { setRange } from '../../../modals/editRange/actions/setRange';
import can from '../../../utils/can';
import createSubject from '../../../utils/createSubject';
import { openRangesMultiedit } from '../../../modals/numberRangeMultiedit/actions/setRanges';
import fetchRangesPaginate from '../actions/fetchRangesPaginate';
import styles from '../styles';
import CustomIconButton from '../../../partials/tableCustomButtons/CustomIconButton';
import RefreshButton from '../../../partials/tableCustomButtons/RefreshButton';

export function NumberRangeTableBE({
  intl,
  client,
  isLoading,
  columns,
  setColumns,
  lockUserAction,
  clientName,
  customJobs,
  refresh,
  setRefresh,
  setRange,
  connectClientId,
  openRangesMultiedit,
  classes
}) {
  const [pageSize, setPageSize] = useState(TABLE_OPTIONS.DEFAULT.pageSize);
  const [getServiceOperator, setGetServiceOperator] = useState(null);
  const [getConnectRanges, setGetConnectRanges] = useState(null);

  const tableDefaultColumns = useMemo(() => {
    return tableColumns({ clientId: client, lockUserAction, isBeTable: true });
  }, [client, lockUserAction]);

  const tableRef = useRef();

  useEffect(() => {
    const telepoRanges = columns.find(
      el =>
        el.title ===
        intl.formatMessage({
          id: 'NUMBER_RANGE.DOMAIN_RANGE'
        })
    );

    if (isDefined(telepoRanges) && !telepoRanges.hidden !== getConnectRanges) {
      setGetConnectRanges(!telepoRanges.hidden);
    }

    const serviceOperator = columns.find(
      el =>
        el.title ===
        intl.formatMessage({
          id: 'OPERATOR'
        })
    );

    if (
      isDefined(serviceOperator) &&
      !serviceOperator.hidden !== getServiceOperator
    ) {
      setGetServiceOperator(!serviceOperator.hidden);
    }

    // eslint-disable-next-line
  }, [columns, getConnectRanges]);

  useEffect(() => {
    setTableColumns({
      setColumns,
      tableDefaultColumns,
      tableName: 'NumberRanges-Admin-1'
    });
  }, [setColumns, tableDefaultColumns, client]);

  useEffect(() => {
    if (refresh) {
      tableRef.current.onQueryChange();
      setRefresh(false);
    }
    // eslint-disable-next-line
  }, [refresh]);

  const fetchData = useCallback(
    query => {
      setPageSize(query.pageSize);
      return fetchRangesPaginate({
        query,
        client,
        getServiceOperator,
        getConnectRanges
      });
    },
    // eslint-disable-next-line
    [setPageSize, client]
  );

  const actions = useMemo(
    () => {
      let buttons = [
        {
          icon: () => <AddButton buttonText="BUTTON.ADD" />,
          onClick: () =>
            setRange({
              range: {},
              resetValues: {
                id: client,
                getServiceOperator,
                getConnectRanges,
                connectClientId
              },
              isEdit: false,
              isBeTable: true
            }),
          isFreeAction: true,
          hidden:
            !can(
              'create',
              createSubject('ExternalNumberRange', {
                client_id: Number(client)
              })
            ) || isUndefined(client)
        },
        {
          tooltip: intl.formatMessage({ id: 'BUTTON.MULTIEDIT' }),
          icon: () => <CustomIconButton iconClassName="far fa-edit" />,
          onClick: (evt, data) =>
            openRangesMultiedit({
              ranges: data,
              resetValues: {
                id: client,
                getServiceOperator,
                getConnectRanges,
                connectClientId
              },
              isBeTable: true
            })
        },
        {
          icon: () => <RefreshButton tableHeader={true} />,
          onClick: () => setRefresh(true),
          isFreeAction: true
        }
      ];

      return buttons;
    },
    // eslint-disable-next-line
    [customJobs, client, getServiceOperator, getConnectRanges]
  );

  const tableOptions = useMemo(() => {
    return {
      ...mapTableOptions({ tableName: 'numberRanges', clientName }),
      pageSize,
      selection: true,
      maxColumnSort: 0
    };
  }, [clientName, pageSize]);

  const localization = useMemo(() => {
    return { ...setLocalization() };
  }, []);

  const onChangeColumnHidden = useCallback(
    (columnData, hidden) => {
      updateTableColumns({
        columnData,
        hidden,
        setColumns,
        tableDefaultColumns,
        tableName: 'NumberRanges-Admin-1'
      });
    },
    [setColumns, tableDefaultColumns]
  );

  return (
    <MaterialTable
      components={{
        Toolbar: props => (
          <MTableToolbar
            classes={{
              title: classes.toolbarTitle,
              spacer: classes.toolbarSpacer,
              actions: classes.toolbarActions,
              highlight: classes.toolbarHighlight
            }}
            {...props}
          />
        )
      }}
      title={<RangesTableHeader showSelector={isDefined(client)} />}
      icons={customTableIcons}
      actions={actions}
      columns={columns}
      data={fetchData}
      options={tableOptions}
      localization={localization}
      tableRef={tableRef}
      onChangeColumnHidden={onChangeColumnHidden}
    />
  );
}

const mapStatesToProps = ({ externalNoRanges, auth, selectClient, loader }) => {
  return {
    ...externalNoRanges,
    isLoading: loader.isSpinVisible,
    clientName: selectClient.selectedClient.name,
    connectClientId: selectClient.selectedClient.connect30_domain,
    userConnectId: auth.user.connect30_user_id,
    customJobs: auth.customJobs
  };
};

const mapDispatchToProps = {
  setColumns: reduxActions.setColumns,
  setRefresh: reduxActions.setRefresh,
  resetExternalNoRanges: reduxActions.resetExternalNoRanges,
  setRange,
  openRangesMultiedit
};

export default flowRight(
  withRouter,
  injectIntl,
  connect(mapStatesToProps, mapDispatchToProps),
  withStyles(styles)
)(NumberRangeTableBE);
