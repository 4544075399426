export default {
  root: {
    display: 'flex',
    marginLeft: '20px',
    fontSize: '12px',
    fontStyle: 'normal',
    fontWeight: 'normal',
    width: '100%',
    justifyContent: 'space-between'
  },
  nameContainer: { display: 'flex', padding: '5px 0' },
  problemsContainer: { display: 'flex', flexDirection: 'column' },
  label: {
    marginRight: '20px'
  }
};
