export default {
  root: {
    display: 'flex',
    flexDirection: 'column',
    height: '160px',
    alignItems: 'center',
    margin: '3px 0'
  },
  label: {
    color: '#646C9A',
    width: '70px',
    fontWeight: 500,
    marginRight: '20px'
  },
  container: {
    height: '40px',
    margin: '5px 0',
    display: 'inherit',
    alignItems: 'center'
  },
  clientField: {
    width: '250px'
  },
  textFieldDisabled: { color: 'hsl(0,0%,20%)', backgroundColor: '#F5F5F5' }
};

export const customSelectStyles = {
  option: {
    fontSize: '12px'
  },
  control: {
    width: '250px'
  }
};
